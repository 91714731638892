<template>
  <div>
    <p class="clearfix mb-0 ml-2 mr-2">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        <!-- <a
          href="https://landing.salpalaran.com/"
          target="_blank"
          rel="noopener noreferrer"
          style="z-index: 999"
        >
          PT Sarana Abadi Lestari
        </a> -->
      </span>
      <span class="float-md-right d-none d-md-block">Buku Tamu
        <feather-icon icon="HeartIcon" size="21" class="text-danger stroke-current" />
      </span>
    </p>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card no-body class="knowledge-base-bg text-center"
        :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` }">
        <b-card-body class="card-body">
          <h2 class="text-primary">
            PT SAL - Monitoring of Visitor
          </h2>
          <b-card-text class="mb-2">
            <span>Popular searches: </span>
            <span class="font-weight-bolder">Reuse QRCode, Create Form</span>
          </b-card-text>

          <!-- form -->
          <b-form class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input id="searchbar" v-model="knowledgeBaseSearchQuery" placeholder="Search .... Powered by Ai" />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content mx-auto">

      <!-- content -->
      <b-row class="kb-search-content-info match-height ml-2 mr-2">
        <!-- :md="item.id === 4 ? 12 : 4"
        :sm="item.id === 4 ? 12 : 6" -->
        <b-col v-for="item in filteredKB"
        :key="item.id"
        :md="item.id === 4 ? 4 : 4"
        :sm="item.id === 4 ? 6 : 6"
        class="kb-search-content">
          <b-card class="text-center cursor-pointer" :img-src="item.img" :img-alt="item.img.slice(5)" img-top
          @click="handleClick(item)"
            >
            <span v-if="item.category === 'create-form-umum'">
              <h4>Create Form <b-badge variant="light-primary">Umum</b-badge> 🧑‍💻</h4>
            </span>
            <h4 v-else>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              <span v-if="item.category === 'login'">
                Your data holds power. Here, every action—from login attempts to registration details—is carefully tracked, ensuring transparency and efficiency in our system for
                <b-badge variant="primary"> Pengguna Jasa </b-badge> ,
                <b-badge variant="primary"> BBM agents </b-badge> and
                <b-badge variant="primary"> Karyawan </b-badge>
              </span>
              <span v-else>
                {{ item.desc }}
              </span>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-show="!filteredKB.length" cols="12" class="text-center">
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </b-col>
      </b-row>
    </section>


    <p class="clearfix mb-0 ml-2 mr-2">
      <span class="float-md-left d-block d-md-inline-block mt-25">
        <small>
          © 2024 - {{ new Date().getFullYear() }}
          <a href="https://github.com/yogithesymbian" target="_blank" rel="noopener noreferrer" style="z-index: 999">
            Yogi Arif Widodo
          </a>
          🎸
          <a href="https://landing.salpalaran.com/" target="_blank" rel="noopener noreferrer" style="z-index: 999">
            PT Sarana Abadi Lestari
          </a>
        </small>
      </span>
      <span class="float-md-right d-none d-md-block">Visitor
        <feather-icon icon="HeartIcon" size="21" class="text-danger stroke-current" />
      </span>
    </p>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend,
  BImg, BBadge
} from 'bootstrap-vue';

export default {
  components: {
    BBadge,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],

    };
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase();
      return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower));
    },
  },
  created() {
    this.$http.get('/kb/data/knowledge_base').then(res => { this.kb = res.data; });
  },
  methods: {
    handleClick(item) {
      if (item.id === 4) {
        // Redirect to external URL
        window.location.href = 'https://docs.salpalaran.com';
      } else {
        // Navigate within the app
        this.$router.push({ name: item.category, params: { category: item.category } });
      }
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>